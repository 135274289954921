import React from "react"
import { motion } from "framer-motion"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import DesktopHeader from "../components/DesktopHeader/DesktopHeader"
import MobileHeader from "../components/MobileHeader/MobileHeader"
import DesktopFooter from "../components/DesktopFooter/DesktopFooter"
import MobileFooter from "../components/MobileFooter/MobileFooter"

import { containerVariants, itemVariants } from "../variants/staggerAppear"

import { useWindowSize } from "../utils/useWindowSize"

const AboutPage = ({ data }) => {
  const { width } = useWindowSize()

  return (
    <Layout>
      <SEO title="About" />
      {width > 1278 ? <DesktopHeader /> : <MobileHeader />}
      <motion.div
        id={"about-container"}
        variants={containerVariants}
        initial={"initial"}
        animate={"loaded"}
      >
        <motion.div id={"about-narrative"} variants={itemVariants}>
          <motion.h1 className={"header uppercase"}>Narrative</motion.h1>
          <motion.p className={"paragraph uppercase"}>
            (IM)MATERIAL IS A CONCEPT STORE THAT EXPLORES THE INTANGIBLE AND
            SELF-REFLECTIVE DIMENSIONS OF PHYSICAL OBJECTS.
          </motion.p>
          <motion.p className={"paragraph uppercase"}>
            THE NAME (IM)MATERIAL REFERS TO THE FACT THAT ALL TANGIBLE OR
            MATERIAL OBJECTS CARRY WITHIN THEM AN INTANGIBLE OR IMMATERIAL
            ESSENCE, THAT BEING THE CREATORS THOUGHT AND/OR THE CONSUMERS
            INTERPRETATION. OUR HYPOTHESIS IS THAT BY CONTEMPLATING AND
            QUESTIONING WHAT THIS ESSENCE IS, THE MORE CONSCIOUS AND EMPOWERED
            INDIVIDUALS WE CAN BECOME.
          </motion.p>
          <motion.p className={"paragraph uppercase"}>
            WE SPECIALIZE IN SALVAGING AND RESURRECTING QUALITY VINTAGE PIECES.
          </motion.p>
          <motion.p className={"paragraph uppercase"}>
            (IM)MATERIAL IS A CURATED VINTAGE STORE SITUATED IN AMSTERDAM AND
            THE WORLD WIDE WEB. WE TRAVEL TO FAR AND NEAR-DISTANT LANDS TO
            HAND-PICK VINTAGE FINDS THAT EVOKE SENSATIONS AND REACTIONS.
          </motion.p>
          <motion.p className={"paragraph uppercase"}>
            EACH GARMENT HAS BEEN THOUGHTFULLY SELECTED ACCORDING TO THE FIT,
            COMFORT, COLOR, TEXTURE, DESIGN(ER), AND PRODUCTION COUNTRY. OUR
            OFFERING IS GENDER-NEUTRAL, STRIVING TO DISSOLVE THE RIGID
            DISTINCTIONS IMPOSED UPON US. WE ALSO EXTEND THE LIFETIME OF
            GARMENTS THROUGH UPCYCLING; CREATING UNIQUE PIECES FROM USED
            CLOTHING, AND BY OFFERING A CONSIGNMENT-SERVICE; SELLING THE
            CHERISHED, BUT UNUSED GARMENTS OF OTHER PEOPLE.
          </motion.p>
          <motion.p className={"paragraph uppercase"}>
            THROUGH PHYSICAL OBJECTS AND PRESENCE, WE ASPIRE TO ESTABLISH A
            DISCOURSE AND BUILD A COMMUNITY THAT ORGANIZES EVENTS THAT
            ENTERTAIN, EDUCATE, AND INSPIRE.
          </motion.p>
          <motion.p className={"paragraph uppercase"}>
            AS DIFFERENT CRAFTS INSPIRE AND PROPEL EACH OTHER, THE STORE IS A
            CELEBRATION OF ART, MUSIC AND CLOTHING, AND THE(IR)
            INTERCONNECTEDNESS BETWEEN THEM. THEREFORE, WE OFFER OUR ONLINE AND
            PHYSICAL SPACE FOR ASPIRING ARTIST TO SPREAD THE WORK AND CREATE
            HAPPENINGS THAT ENTERTAIN, EDUCATE, AND INSPIRE.
          </motion.p>
        </motion.div>
        <motion.div id={"about-mission"} variants={itemVariants}>
          <motion.h1 className={"header uppercase"}>Mission</motion.h1>
          <motion.p className={"paragraph uppercase"}>
            (IM)MATERIAL, IN ESSENCE, IS A COUNTER-MOVEMENT TOWARDS THE
            TECHNOLOGICAL AND SOCIAL ISOLATION THAT WE ARE NOW EXPERIENCING.
            ALTHOUGH THE INTERNET, E-COMMERCE AND SOCIAL MEDIA HAVE
            REVOLUTIONIZED THE WORLD, THEY HAVE EQUALLY DISTORTED OUR SENSE OF
            SELF AND OUR RELATION TO THE REST OF THE MATERIAL WORLD, AND WE SEE
            PHYSICALITY AS THE REMEDY.
          </motion.p>
          <motion.p className={"paragraph uppercase"}>
            PHYSICALITY FACILITATES THE GENUINE EXCHANGE AND SYNTHESIS THOUGHTS,
            IDEAS AND INSPIRATIONS THAT ARE VITAL FOR OUR INDIVIDUAL AND
            COLLECTIVE OUTPUT AND GROWTH. PHYSICALITY IS FUNDAMENTAL FOR
            ESTABLISHING CONNECTIONS WITH ONE ANOTHER AND THE THINGS WE CONSUME.
            IT INTRODUCES THE DEPTH AND NUANCE IN EXPERIENCE THAT WE NEED TO
            HAVE CLARITY ON WHAT IS IMPORTANT AND WHAT IS NOT, ULTIMATELY GIVING
            MEANING TO LIFE. WITHOUT PHYSICALITY, WE WOULD BECOME THE
            SELF-ABSORBED, PSYCHOLOGICALLY DESOLATED BEINGS THAT NEOLIBERAL,
            TECHNOCRATIC SOCIETIES HAVE FORESHOWN.
          </motion.p>
          <motion.p className={"paragraph uppercase"}>
            THEREFORE, WE BELIEVE THAT ENVIRONMENTAL, COLLECTIVE AND PERSONAL
            TRANSFORMATION IS THE BY-PRODUCT OF FEELING INSPIRED AND MOTIVATED
            TO ASK YOURSELF AND ACT UPON QUESTIONS LIKE; WHO AM I SUPPORTING BY
            ACQUIRING THIS? WHAT AM I COMMUNICATING AND ADVOCATING BY BUYING
            THIS? WHY AM I ATTRACTED TO THIS?
          </motion.p>
          <motion.p className={"paragraph uppercase"}>
            ONLY THROUGH THIS INNER TRANSFORMATION, WE ARE ABLE TO TRANSMUTE THE
            WORLD.
          </motion.p>
        </motion.div>
        <motion.div id={"about-contact"} variants={itemVariants}>
          <motion.h1 className={"header uppercase"}>Contact</motion.h1>
          <motion.p className={"paragraph uppercase"}>
            FOR QUESTIONS AND/OR INQUIRIES FEEL FREE TO SEND AN EMAIL TO{" "}
            <a
              href={"mailto:immaterialcorp@gmail.com"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              IMMATERIALCORP@GMAIL.COM
            </a>
          </motion.p>
          <motion.p className={"paragraph uppercase"}>
            FIND US ON{" "}
            <a
              href={"https://www.instagram.com/immaterialcorp/"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              INSTAGRAM
            </a>{" "}
            OR VISIT OUR PHYSICAL STORE AT{" "}
            <a
              href={"https://g.page/im-material-amsterdam?share"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              Haarlemmerdijk 171, 1013 KH Amsterdam
            </a>
          </motion.p>
        </motion.div>
        <motion.div id={"about-image"} variants={itemVariants}>
          <Img fluid={data.aboutImage.childImageSharp.fluid} />
        </motion.div>
      </motion.div>
      {width > 1278 ? <DesktopFooter /> : <MobileFooter />}
    </Layout>
  )
}

export const data = graphql`
  query AboutImage {
    aboutImage: file(relativePath: { eq: "about-image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default AboutPage
